// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";





export default function data() {
  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>

  );

  

  return {
    columns: [
      { Header: "Name", accessor: "Name", width: "10%" },
      { Header: "Email", accessor: "Email", width: "10%" },
      { Header: "Date", accessor: "Date", width: "10%" },
      { Header: "Visit Time", accessor: "Visit Time", width: "10%" },
      { Header: "Number", accessor: "Number", width: "10%" },
      { Header: "Doctor", accessor: "Doctor", width: "10%" },
      { Header: "Injury/Condition", accessor: "Injury/Condition", width: "10%" },
      { Header: "Actions", accessor: "Actions", width: "10%" },
    ],
  
    rows: [
      {
        Name: (
          <MDTypography variant="h8" color="text" fontWeight="small">
            Momin khan
          </MDTypography>
        ),
        Email: (
          <MDTypography variant="h8" color="text" fontWeight="small">
            Ryan@gmail.com
          </MDTypography>
        ),
        phone: (
          <MDTypography variant="h8" color="text" fontWeight="small">
            1111111111
          </MDTypography>
        ),
        Diseas: (
          <MDTypography variant="h8" color="text" fontWeight="small">
            Chest Pain
          </MDTypography>
        ),
      },
    ],

  };
}
