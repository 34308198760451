import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: 'https://2284-2404-3100-1045-72b0-250b-1361-7f15-fc7d.ngrok-free.app/api/' }),
  endpoints: (builder) => ({
    createAppointment: builder.mutation({
      query: ({id,appointmentData}) => {
        if(id === 0){
        return {
        url: `appointments`,
        method: 'POST',
        body: {
          appointment: {
            name: appointmentData.appointment.name,
            email:  appointmentData.appointment.email,
            appointment_date: appointmentData.appointment.appointment_date,
            appointment_from: appointmentData.appointment.appointment_from,
            appointment_to: appointmentData.appointment.appointment_to,
            no:appointmentData.appointment.no,
            inquiry: appointmentData.appointment.inquiry,
            doctor_id:appointmentData.appointment.doctor_id,
            room_id:appointmentData.appointment.room_id,
            user_id:appointmentData.appointment.user_id
          },
      },
    }
  }else{
    return {
      url: `appointments/${id}`,
      method: 'put',
      body: {
        appointment: {
          name: appointmentData.appointment.name,
          email:  appointmentData.appointment.email,
          appointment_date: appointmentData.appointment.appointment_date,
          appointment_from: appointmentData.appointment.appointment_from,
          appointment_to: appointmentData.appointment.appointment_to,
          no:appointmentData.appointment.no,
          inquiry: appointmentData.appointment.inquiry,
          doctor_id:appointmentData.appointment.doctor_id,
          room_id:appointmentData.appointment.room_id,
          user_id:appointmentData.appointment.user_id
        },
    },
  }
  }
      },
    }),
    getCompany: builder.query({
      query: () => {
        return {
        url: 'addCompany',
        method: 'get',
    }
      },
    }),
  }),
});

export const { useCreateAppointmentMutation,useGetCompanyQuery } = api;