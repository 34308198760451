// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Appointments from "layouts/appointments";
import Doctor from "layouts/doctors";
import Departments from "layouts/deparments";
import Payment from "layouts/payments";
import DoctorDashboard from "layouts/doctorDashboard";
import AddCompnay from "layouts/appointments";

const routes = [

  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <img src={require('../src/assets/images/dashboard.png')} alt="Dashboards" width='25rem'/>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Add Compnay",
    key: "addcompany",
    icon: <img src={require('../src/assets/images/commission.png')} alt="AddCompnay" width='25rem'/>,
    route: "/AddCompnay",
    component: <AddCompnay />,
  },
  {
    type: "collapse",
    name: "Add User",
    key: "doctors",
    icon: <img src={require('../src/assets/images/doctor.png')} alt="Appointments" width='25rem'/>,
    route: "/doctors",
    component: <Doctor />,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <img src={require('../src/assets/images/profile.png')} alt="Profile" width='25rem'/>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Logout",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];

export default routes;







