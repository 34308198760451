
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import { useEffect } from "react";
import Cookies from 'js-cookie';

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;

  useEffect(() => {
    // Retrieve user ID from cookies
    const userId = Cookies.get('UserId');
    console.log('User ID from cookie:', userId);

    // Perform any actions you need with the user ID
  }, []);
 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon={require('../../../src/assets/images/appointment.png')}
                title="Appointments"
                count={281}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
               color="info"
               icon={require('../../../src/assets/images/doctor-visit.png')}
                title="New Patients"
                count="2,300"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon={require('../../../src/assets/images/surgeon.png')}
                title="Operations"
                count="34"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon={require('../../../src/assets/images/rupee.png')}
                title="Earnings"
                count="91k"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Hospital Survey"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container mt={3} justifyContent={"space-evenly"} alignItems={"center"}>
          <Grid item xs={12} md={6} lg={4} ml={-20}>
              <MDBox mb={1} width="35rem">
                <ReportsBarChart
                  color="info"
                  title="Income In Current Month"
                  description="Last Campaign Performance"
                  date="updated last 24 hours"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1} width="35rem">
                <ReportsBarChart
                  color="info"
                  title="Income In Current Week"
                  description="Last Campaign Performance"
                  date="updated last 24 hours"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid>
              <MDBox width="72rem">
              <Projects/>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
