import { useState, useRef, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Button, Modal, getAccordionDetailsUtilityClass, Alert } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "CommonURL/commonurls";
import { RingLoader } from 'react-spinners';
import { ColorRing } from "react-loader-spinner";





const AddCompnay = () => {

  const [companyName, setCompanyName] = useState('')
  const [tradeLicNo, setTradeLicNo] = useState('')
  const [state, setState] = useState('')
  const [contactNo, setContactNo] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [activity, setActivity] = useState('')
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [array, setArray] = useState([])
  const [loading, setLoading] = useState('')
  const [id, setId] = useState(0)


  const openAddModal = () => setAddModalOpen(true);
  const closeAddModal = () => setAddModalOpen(false);

  const handleAddAppointmentClick = () => {
    openAddModal()
  }

  useEffect(() => {
    getData()
  }, [])

  const addPost = async (e) => {
    e.preventDefault();
    try {
      if (id === 0) {
        const res = await axios.post(BASE_URL + 'addcompany', {
          companyName: companyName,
          tradeLicNo: tradeLicNo,
          state: state,
          contactNo: contactNo,
          email: email,
          password: password,
          activity: activity
        }).then((res) => (
          console.log('respinse', res.data),
          toast.success('Company added successfully'),
          closeAddModal(true)
        ))
        await getData()
      } else {
        const res = await axios.put(BASE_URL + `addcompany/${id}`, {
          companyName: companyName,
          tradeLicNo: tradeLicNo,
          state: state,
          contactNo: contactNo,
          email: email,
          password: password,
          activity: activity
        }).then((res) => (
          console.log('respinse', res.data),
          toast.success('Company added successfully'),
          closeAddModal(true)
        ))
        await getData();
      }
    } catch (error) {
      console.log('Error', error)
    }
  }

  const getData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(BASE_URL + 'addCompany', {
        headers: {
          'ngrok-skip-browser-warning': 'true'
        }
      });
      console.log('Response:', response.data.data);
      setArray(response.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error:', error);
    }
  }

  const deleteData = async (id) => {
    try {
      const response = await axios.delete(BASE_URL + `addCompany/${id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'true'
        }
      });
      console.log('Response:', response.data.data);
      await getData()
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const Data = async (id) => {
    setLoading(true)
    try {
      const response = await axios.get(BASE_URL + `addCompany/${id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'true'
        }
      });
      console.log('Response:', response.data.data);
      setCompanyName(response.data.data.companyName)
      setTradeLicNo(response.data.data.tradeLicNo)
      setState(response.data.data.state)
      setContactNo(response.data.data.contactNo)
      setEmail(response.data.data.email)
      setPassword(response.data.data.password)
      setActivity(response.data.data.activity)
      setLoading(false)
      openAddModal();
    } catch (error) {
      setLoading(false)
      console.error('Error:', error);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Card>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDBox>
            <MDTypography variant="h2" gutterBottom>
              Registerd Companies
            </MDTypography>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth onClick={handleAddAppointmentClick}>
              Add Compnay
            </MDButton>
          </MDBox>
        </MDBox>
        {loading ?
          (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><ColorRing
              visible={true}
              height="50"
              width="50"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={['black']}
            /></div>
          )
          :
          (
            <Card>
              <table class="table table-striped">
                <thead style={{ height: "50px", borderRightWidth: 2, borderRightColor: "black" }}>
                  <tr>
                    <th scope="col" style={{ fontSize: "14px", color: "black", width: "14.28%", fontWeight: "bold" }}>Compnay Name</th>
                    <th scope="col" style={{ fontSize: "14px", color: "black", width: "14.28%", fontWeight: "bold" }}>Trade Lic No</th>
                    <th scope="col" style={{ fontSize: "14px", color: "black", width: "14.28%", fontWeight: "bold" }}>State</th>
                    <th scope="col" style={{ fontSize: "14px", color: "black", width: "14.28%", fontWeight: "bold" }}>Contact No</th>
                    <th scope="col" style={{ fontSize: "14px", color: "black", width: "14.28%", fontWeight: "bold" }}>Email</th>
                    <th scope="col" style={{ fontSize: "14px", color: "black", width: "14.28%", fontWeight: "bold" }}>Password</th>
                    <th scope="col" style={{ fontSize: "14px", color: "black", width: "14.28%", fontWeight: "bold" }}>Activity</th>
                  </tr>
                </thead>
                <tbody alignItems="center">
                  {array.map((item) => (
                    <tr key={item.id}>
                      <td class="text-center" style={{ textAlign: "center", fontSize: 2 }}>
                        <MDTypography fontWeight="light" color="black" mt={1} >
                          <p style={{ fontSize: 14 }}>{item.companyName}</p>
                        </MDTypography>
                      </td>
                      <td style={{ fontSize: "14px", textAlign: "center", color: "darkblue" }}>
                        <MDTypography fontWeight="light" color="black" mt={1} >
                          <p style={{ fontSize: 14 }}>{item.tradeLicNo}</p>
                        </MDTypography>
                      </td>
                      <td style={{ fontSize: "14px", textAlign: "center" }}>
                        <MDTypography fontWeight="light" color="black" mt={1} >
                          <p style={{ fontSize: 14 }}>{item.state}</p>
                        </MDTypography>
                      </td>
                      <td style={{ fontSize: "14px", textAlign: "center" }}>
                        <MDTypography fontWeight="light" color="black" mt={1} >
                          <p style={{ fontSize: 14 }}>{item.contactNo}</p>
                        </MDTypography>
                      </td>
                      <td style={{ fontSize: "14px", textAlign: "center", color: "darkblue" }}>
                        <MDTypography fontWeight="light" color="black" mt={1} >
                          <p style={{ fontSize: 14 }}>{item.email}</p>
                        </MDTypography>
                      </td>
                      <td style={{ fontSize: "14px", textAlign: "center" }}>
                        <MDTypography fontWeight="light" color="black" mt={1} >
                          <p style={{ fontSize: 14 }}>{item.password}</p>
                        </MDTypography>
                      </td>
                      <td style={{ fontSize: "14px", textAlign: "center" }}>
                        <MDTypography fontWeight="light" color="black" mt={1} >
                          <p style={{ fontSize: 14 }}>{item.activity}</p>
                        </MDTypography>
                      </td>
                      <td style={{ fontSize: "14px", textAlign: "center" }}>
                        <td style={{ fontSize: "14px" }}>
                          <td style={{ fontSize: "14px", display: "flex", justifyContent: "space-around" }}>
                            <MDButton variant="gradient" color="info" fullWidth style={{ maxWidth: "30%" }} onClick={() => [Data(item._id), setId(item._id)]}>
                              <img src={require('../../../src/assets/images/writing.png')} alt="Edit" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                            </MDButton>
                            <MDButton variant="gradient" color="error" style={{ maxWidth: "20%", maxHeight: "10%" }}
                              onClick={() => { deleteData(item._id) }}>
                              <img src={require('../../../src/assets/images/delete.png')} alt="Delete" style={{ maxWidth: "40px", maxHeight: "20px" }} />
                            </MDButton>
                          </td>
                        </td>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          )}
      </Card>
      <Modal
        open={isAddModalOpen}
        onClose={closeAddModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card style={{ width: '80%', maxWidth: '500px' }}>
          <MDBox p={3}>
            <MDBox mt={1}>
              <MDInput
                type="name"
                label="Compnay Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mt={1}>
              <MDInput
                type="Email"
                label="Trade Lic No"
                value={tradeLicNo}
                onChange={(e) => setTradeLicNo(e.target.value)}
                fullWidth
              />
            </MDBox>

            <MDBox mt={1}>
              <MDBox mt={1}>
                <MDInput
                  type="phonenumber"
                  label="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  fullWidth
                />
              </MDBox>
              <MDBox mt={1}>
                <MDInput
                  type="injury"
                  label="Contact No"
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                  fullWidth
                />
              </MDBox>
              <MDBox mt={1}>
                <MDInput
                  type="injury"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </MDBox>
              <MDBox mt={1}>
                <MDInput
                  type="injury"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                />
              </MDBox>
              <MDBox mt={1}>
                <MDInput
                  type="injury"
                  label="Activity"
                  value={activity}
                  onChange={(e) => setActivity(e.target.value)}
                  fullWidth
                />
              </MDBox>
            </MDBox>
            <MDBox mt={2} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <MDButton variant="gradient" color="info" onClick={closeAddModal} style={{ maxWidth: '60%' }}>
                Cancel
              </MDButton>
              <MDButton variant="gradient" color="info" style={{ maxWidth: '60%' }} onClick={addPost}>
                Add Company
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>

    </DashboardLayout>
  );
};

export default AddCompnay;

