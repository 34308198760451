import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import axios from "axios";
import { BASE_URL } from "CommonURL/commonurls";
import Cookies from 'js-cookie';
import { useDispatch } from "react-redux";
import { setUserType } from "store/myReducer";

function Basic() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [userId, setUserId] = useState('')
  const [passwordError, setPasswordError] = useState("");
  const [type, setType] = useState('')
  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const dispatch = useDispatch();

  //saving value in variables
  const saveUserType = (id) => {
    dispatch(setUserType(id))
  }


  // const validateForm = () => {
  //   let valid = true;

  //   if (!email) {
  //     setEmailError("Email is required");
  //     valid = false;
  //   } else {
  //     setEmailError("");
  //   }

  //   if (!password) {
  //     setPasswordError("Password is required");
  //     valid = false;
  //   } else {
  //     setPasswordError("");
  //   }

  //   return valid;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

      try {
        const response = await axios.post(BASE_URL + 'login', {
          Name: name,
          Password: password,
        });

        console.log('API Response:', response.data);
        navigate('/dashboard')
      } catch (error) {
        console.error('Errijhsjhdjhdjhk:', error);
      }
  };


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="small" color="white" mt={1}>
            HMS
          </MDTypography>
          <MDTypography fontWeight="light" color="white" mt={1}>
            <p style={{ fontSize: "15px" }}>Please enter your email and password</p>
          </MDTypography>

        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {/* <p style={{ fontSize: "12px", color: "red", marginLeft: "5px" }}>{emailError}</p> */}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError !== ""}
              />
              <p style={{ fontSize: "12px", color: "red", marginLeft: "5px" }}>{passwordError}</p>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                Login
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
