import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  myVariable: null, // Initial value of your variable
};

const mySlice = createSlice({
  name: 'myReducer',
  initialState,
  reducers: {
    setUserType: (state, action) => {
        state.userType = action.payload;
      },
  },
});

export const {
    setUserType
}
  = mySlice.actions;

export default mySlice.reducer;