import { configureStore } from '@reduxjs/toolkit';
import { api } from 'services/apiSlice';
import myReducer from './myReducer';


const store = configureStore({
  reducer: {
    myReducer: myReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export default store;