const BASE_URL = "https://7b56-2404-3100-104b-2417-3dc0-4ffa-5a82-754b.ngrok-free.app/api/";
module.exports = {
    BASE_URL : "https://7b56-2404-3100-104b-2417-3dc0-4ffa-5a82-754b.ngrok-free.app/api/",
    ASSET_URL : "https://7b56-2404-3100-104b-2417-3dc0-4ffa-5a82-754b.ngrok-free.app",
    SIGNUP_USER : BASE_URL + 'users',
    LOGIN_USER : BASE_URL + 'sessions',
    TAX_CALCULATION : BASE_URL + 'taxations',
    REGISTRATION_OTP : BASE_URL + 'users/validate_otp',
    SelectYears : BASE_URL + 'fiscalyears',
    Occupations: BASE_URL + 'occupations',
    TaxFillings: BASE_URL +'taxfillings' ,
    Personals: BASE_URL +'personals'

};